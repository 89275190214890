import React from "react"
import styled from "@emotion/styled"
import { debounce, sortBy } from "lodash"
import { graphql } from "gatsby"
import { GiPianoKeys } from "react-icons/gi"
import { FaGuitar } from "react-icons/fa"
import { IoMdMicrophone } from "react-icons/io"

import Seo from "../components/seo"
import Layout from "../components/layout"

import ImageLoader from "../components/image-loader"
import { borders, colors, shadows } from "../styles"

const Thumbnail = styled.div`
  cursor: pointer;
  flex: 1 1 auto;

  .gatsby-image-wrapper {
    height: 12rem;
    border: ${borders.standard};
    border-radius: 0;
    border: ${borders.standard};
    box-shadow: ${shadows.standard};
    overflow: hidden;

    @media screen and (min-width: 768px) {
      border-radius: 0.5rem;
    }
  }
`

const Thumbnails = styled.div`
  display: flex;
  justify-content: center;
  gap: 0rem;

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
`

const SelectedTeacher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;

  @media screen and (min-width: 768px) {
    gap: 1rem;
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }
`

const SelectedTeacherImage = styled.div`
  .gatsby-image-wrapper {
    height: 30rem;
    border-radius: 0rem;
    border: ${borders.standard};
    box-shadow: ${shadows.standard};
    overflow: hidden;

    @media screen and (min-width: 768px) {
      border-radius: 0.5rem;
    }

    @media screen and (min-width: 1280px) {
      aspect-ratio: 4 / 3;
    }
  }
`

const BioCard = styled.div`
  flex: 1 1 auto;
  padding: 1rem 2rem;
  border-radius: 0;
  border: ${borders.standard};
  box-shadow: ${shadows.standard};
  background: ${colors.primary};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
    padding: 0.75rem;
    border-radius: 100%;
    background: ${colors.darkGrey};
  }
`

const TeacherQuote = styled.blockquote`
  margin: 0;
  font-weight: 100;
  font-size: 1.25rem;
`

export default class TeachersPage extends React.Component {
  state = { activePosition: 0 }

  getTeacherByPosition(position) {
    const target = this.props.data.teachers.edges.find(
      ({ node }) => node.frontmatter.position === position
    )
    return target.node
  }

  get activeTeacher() {
    const { activePosition } = this.state
    return this.getTeacherByPosition(activePosition)
  }

  selectTeacher = debounce((activePosition) => {
    this.setState({ activePosition })
  }, 10)

  handleClick = (e) => {
    if (!!e.currentTarget) {
      const selectedPosition = Number(
        e.currentTarget.getAttribute("data-position")
      )

      this.selectTeacher(selectedPosition)
    }
  }

  renderTeacherThumbs() {
    const { activePosition } = this.state
    const teachers = this.props.data.teachers.edges
    const sorted = sortBy(teachers, ["node.frontmatter.position"])

    return sorted
      .filter(({ node }) => node.frontmatter.position !== activePosition)
      .map(({ node }) => (
        <Thumbnail
          role="presentation"
          onClick={this.handleClick}
          data-position={node.frontmatter.position}
          key={node.frontmatter.firstname}
        >
          <ImageLoader
            alt={node.frontmatter.firstname}
            src={node.frontmatter.image}
          />
        </Thumbnail>
      ))
  }

  renderSkillIcons() {
    return this.activeTeacher.frontmatter.skills.map((skill) => {
      switch (skill) {
        case "voice":
          return <IoMdMicrophone />
        case "piano":
          return <GiPianoKeys />
        case "guitar":
          return <FaGuitar />
        default:
          return <></>
      }
    })
  }

  render() {
    return (
      <Layout>
        <Seo title="Teachers | Sing Smart Studios | Townsville Singing School" />
        <h1 style={{ textAlign: "center" }}>Our teaching team</h1>

        <Thumbnails>{this.renderTeacherThumbs()}</Thumbnails>

        <SelectedTeacher>
          <SelectedTeacherImage>
            <ImageLoader
              alt={this.activeTeacher.frontmatter.firstname}
              src={this.activeTeacher.frontmatter.image}
            />
          </SelectedTeacherImage>

          <BioCard>
            <h1>
              {this.activeTeacher.frontmatter.firstname}{" "}
              {this.activeTeacher.frontmatter.lastname}
            </h1>
            <Title>
              {this.activeTeacher.frontmatter.title} {this.renderSkillIcons()}
            </Title>
            <TeacherQuote
              dangerouslySetInnerHTML={{
                __html: this.activeTeacher.html,
              }}
            />
          </BioCard>
        </SelectedTeacher>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    teachers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown/teachers/" } }
      sort: { fields: frontmatter___position, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            firstname
            lastname
            title
            image
            position
            skills
          }
          html
        }
      }
    }
  }
`
